import React, { useState, useEffect } from 'react';
import './Wiki.css';
import { useTranslation } from 'react-i18next';

export default function Wiki () {
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(['main']);

  useEffect(() => {
    fetch('/wiki.json')
      .then(res => res.json())
      .then(jsonData => {
        setData(jsonData);
        setLoading(false);
      });
  }, []);

  const showTab = (index: number) => {
    setActiveTab(index);
  };

  const tabNames = ['UPM', 'AVERY', 'Fedrigoni', 'HERMA', 'VPF'];

  return (
    <div className="wiki-container">
      <h1 className="wiki-title">
        {t('main:wiki.title')}
      </h1>

      {/* Tabs Navigation */}
      <div className="tabs">
        {tabNames.map((tab, index) => (
          <div
            key={index}
            className={`tab ${activeTab === index ? 'active' : ''}`}
            onClick={() => showTab(index)}
            onKeyDown={e => (e.key === 'Enter' || e.key === ' ') && showTab(index)}
            role="button"
            tabIndex={0}
          >
            {tab}
          </div>
        ))}
      </div>

      {/* Tab Content */}
      {loading ? (
        <p className="loading-text">
          {t('main:wiki.loading')}
        </p>
      ) : (
        tabNames.map((tab, index) => (
          <div key={index} className={`tab-content ${activeTab === index ? 'active' : ''}`}>
            <h2>
              {tab}
            </h2>
            <div className="table-container">
              <table className="table-body">
                <thead>
                  <tr className="table-col">
                    <th className='table-header'>
                      Kleber Name
                    </th>
                    <th className='table-header'>
                      Klebeeigenschaft
                    </th>
                    <th className='table-header'>
                      Beschichtungstechnologie
                    </th>
                    <th className='table-header'>
                      Beschreibung
                    </th>
                    <th className='table-header'>
                      Anwendung
                    </th>
                    <th className='table-header'>
                      Anwendnung Material
                    </th>
                    <th className='table-header'>
                      Tack (Anfangshaftung)
                    </th>
                    <th className='table-header'>
                      Peel adhesion (Endhaftung)
                    </th>
                    <th className='table-header'>
                      Köhäsion / Scherfestigkeit2
                    </th>
                    <th className='table-header'>
                      Mindestverklebe-temperatur (°C)
                    </th>
                    <th className='table-header'>
                      Minimale Anwendungs-temperatur
                    </th>
                    <th className='table-header'>
                      Maximale Anwendungs-temperatur
                    </th>
                    <th className='table-header'>
                      BEMERKUNG 1
                    </th>
                    <th className='table-header'>
                      BEMERKUNG 2
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data[tab]?.length > 0 ? (
                    data[tab].map((item: any, idx: number) => (
                      <tr key={idx}>
                        <td className="table-row">
                          {item.KleberName}
                        </td>
                        <td className="table-row">
                          {item.Klebeeigenschaft}
                        </td>
                        <td className="table-row">
                          {item.Beschichtungstechnologie}
                        </td>
                        <td className="table-row">
                          {item.Beschreibung || ''}
                        </td>
                        <td className="table-row">
                          {item.Anwendung || ''}
                        </td>
                        <td className="table-row">
                          {item.AnwendnungMaterial || ''}
                        </td>
                        <td className="table-row">
                          {item.Tack || ''}
                        </td>
                        <td className="table-row">
                          {item.PeelAdhesion || ''}
                        </td>
                        <td className="table-row">
                          {item.Kohasion || ''}
                        </td>
                        <td className="table-row">
                          {item.Mindestverklebtemperatur || ''}
                        </td>
                        <td className="table-row">
                          {item.MinimaleAnwendungstemperatur || ''}
                        </td>
                        <td className="table-row">
                          {item.MaximaleAnwendungstemperatur || ''}
                        </td>
                        <td className="table-row">
                          {item.BEMERKUNG1 || ''}
                        </td>
                        <td className="table-row">
                          {item.BEMERKUNG2 || ''}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="table-col">
                      <td colSpan={14} className="no-data">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
